const Networks = {
  1:{
    contracts:{
      tokens:[
        "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
        "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      ],
      RouterAddress:""
    }
  },
  56:{
    contracts:{
      tokens:[
        "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56", //BUSD
        "0x55d398326f99059fF775485246999027B3197955", //USDT
        "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d", //USDC
        "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3", //DAI
        "0x72C1Cc8e5a103FcD4d12Dc20b91e5D95505789B3", //DPG
      ],
      RouterAddress:"0x81b2afc700E869B4a5043D5f2C1b73dD0eE65099"
    }
  },
  137:{
    contracts:{
      tokens:[
        "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
        "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063"
      ],
      RouterAddress:"0x2dA58fCB7F4d91a6C8bB34e44372d9be1237CF17"
    }
  },
  }
  export const networks = Networks

