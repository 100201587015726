import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'

import { WagmiConfig } from 'wagmi'
import {mainnet, bsc, polygon } from 'wagmi/chains'

const projectId = '8853e23f48c2f8ed7d1891e93ee13435'

const chains = [ mainnet, bsc, polygon]
const wagmiConfig = defaultWagmiConfig({ chains, projectId })

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains })

const root = ReactDOM.createRoot(document.getElementById('root'));

      root.render(         

        <BrowserRouter>
          <WagmiConfig config={wagmiConfig}>
           <App />
          </WagmiConfig>
        </BrowserRouter>      
           
      );

