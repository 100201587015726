import React from 'react';

import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';

const Shar = () => {
  var pageURL = window.location.href;
  const shareUrl = pageURL

  return (
   
    <div>
    <FacebookShareButton
      url={shareUrl}
      quote={'Title or jo bhi aapko likhna ho'}
      hashtag={'#portfolio...'}
        >
      <FacebookIcon size={40} round={true} className= "me-3" />
    </FacebookShareButton>

    <WhatsappShareButton
      url={shareUrl}
      quote={'Title or jo bhi aapko likhna ho'}
      hashtag={'#portfolio...'}
    >
      <WhatsappIcon size={40} round={true} className= "me-3" />
    </WhatsappShareButton>

    <TelegramShareButton
      url={shareUrl}
      quote={'Title or jo bhi aapko likhna ho'}
      hashtag={'#portfolio...'}
    >
      <TelegramIcon size={40} round={true} className= "me-3" />
    </TelegramShareButton>

    <TwitterShareButton
      url={shareUrl}
      quote={'Title or jo bhi aapko likhna ho'}
      hashtag={'#portfolio...'}
    >
      <TwitterIcon size={40} round={true}  className= "me-3"/>
    </TwitterShareButton>

    <EmailShareButton
      url={shareUrl}
      quote={'Title or jo bhi aapko likhna ho'}
      hashtag={'#portfolio...'}
    >
      <EmailIcon size={40} round={true} className= "me-3"/>
    </EmailShareButton>

  </div>
  );
};

export default Shar;



