import React from 'react';
import './index.css';
import Notfound from './Notfound';
import Error from './Error';
import {Route , Routes , Navigate} from 'react-router-dom';
import Pay from './Pay';
import Test from './Test';
const App = () => {

  return (
      <>
         <Routes>
            <Route path='/404' element={<Notfound />} />
            <Route path='/pay/:id' element={<Pay />} />
            <Route path='/Test' element={<Test />} />

            <Route path='/error' element={<Error />} />
            <Route path='/' element={<Navigate to="/404" />} />
            <Route path='*' element={<Navigate to="/404" />} />
          </Routes>
      </>
  );
};

export default App;

