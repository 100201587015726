import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notify = (type) => {

    if (type === "success"){
        toast.success('transaction Successful ✌️', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"colored"
            });
    }else if (type === "senting"){
        toast.info('transaction sent to blockchain', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"colored"
            });
        }else if (type === "copy"){
            toast.info('Copied successfully', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"colored"
                });
    }else if (type === "pending"){
        toast.info('transaction is pending ...', {
            position: "top-right",
            autoClose: 40000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"colored"
            });
    }else if (type === "expier3"){
        toast.info('3 minutes left...! ⏳ ', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"colored"
            });
    }else if (type === "expier1"){
        toast.info('1 minutes left...! ⌛ ', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"colored"
            });
    }else if (type === "expier"){
        toast.warn('Your transaction has timed out... 💤 ', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"colored"
            });
    }
};
