import React from 'react';
import { useTimer } from 'react-timer-hook';
import { useEffect,useState } from 'react';
import { notify } from "./toast";
import axios from 'axios';

const Timer = (props) => {

  const [timernotify, settimernotify] = useState();
  const [time, setTime] = useState();

  const timeapi = props.CreateTime;
  const countDownDate = new Date(timeapi).getTime();
  const timethree = countDownDate + 1800000;
  const clear = setInterval(timers, 1000);

  function timers() {
    var now = new Date().getTime();
    var distance = timethree - now;
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (distance > 0) {
      setTime(minutes + "m " + seconds + "s ");
      settimernotify(minutes + "0" + seconds);
    } else if (distance < 0) {
      clearInterval(clear);
      setTime("expier");
    }
  }

  useEffect(() => {
    if (timernotify == 300) {
      notify("expier3");
    }
    if (timernotify == 100) {
      notify("expier1");
    }
    if (time === "expier") {
      notify("expier");
      axios
        .post(`${process.env.REACT_APP_API_URL}/expier/${props.order_id}`, {})
        .then((res) => {
          window.location =
            res.data.backlink + "&status=13&Authority=" + props.order_id;
        });
    }
    return () => {
      clearInterval(clear);
    };
  }, [timernotify, time]);

      return (
        <>
        {time}
        </>
      );
    }
    export default Timer;