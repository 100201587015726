import axios from "axios";
import { formatEther, parseEther } from "ethers";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApproveButton from "./Components/ApproveButton";
import ConfirmButton from "./Components/ConfirmButton";
import LoginButton from "./Components/LoginButton";
import Shar from "./Components/Shar";
import Timer from "./Components/Timer";
import { networks } from "./MainNetwork";

//API
const Pay = () => {

  const [isDPG, setisDPG] = useState(false);
  const [item, setItems] = useState(items);
  const [StatusPage, setStatusPage] = useState("");

  const [select_coin_text, set_select_coin_text] = useState(
    "Please Select a Coin to Pay"
  );
  const [disable_select_coin_input, set_disable_select_coin_input] =
    useState(false);
  const [disable_select_network_input, set_disable_select_network_input] =
    useState(false);
  const [user_selected_coin, set_user_selected_coin] = useState(null);
  const [user_selected_network, set_user_selected_network] = useState(null);
  const [shop, setShop] = useState([]);
  const [statustoken, setStatusToken] = useState();

  const params = useParams();
  const Url = params.id;
  var str = Buffer.from(Url, "base64").toString();
  const User_id = str.slice(0, 24);
  const shop_id = str.slice(24, 48);
  const order_id = str.slice(48, 72);
  const totalItems = items.length;
  const numberOfActiveItems = item.filter((o) => o.active).length;
  const progressBarWidth =
    totalItems > 1 && numberOfActiveItems > 0
      ? ((numberOfActiveItems - 1) / (totalItems - 1)) * 100
      : 0;

  const Max = "1000000000000000000000";
  const UpFixProfit = "1";
  const UpPercentProfit = "25";
  const DownFixProfit = "1";
  const DownPercentProfit = "0";

  const api_shop = `${process.env.REACT_APP_API_URL}/api/shop/shopshowpay`;
  // ================================================================================
  const getshop = async () => {
    const res = await axios.get(`${api_shop}/${User_id}${shop_id}`);
    return res.data;
  };

  useEffect(() => {
    const fetchAPI = async () => {
      const data = await getshop();
      setShop(data);
    };
    fetchAPI();
  }, []);

  // ================================================================================

  const api_order = `${process.env.REACT_APP_API_URL}/order/pay`;
  const getorder = async () => {
    const res = await axios.get(`${api_order}/${order_id}`);
    return res.data;
  };
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    const fetchAPI = async () => {
      const data = await getorder();

      setOrders(data);
      setStatusToken(data.donate);
    };
    fetchAPI();
  }, []);

  // ================================================================================

  const click = (index) => {
    const items = item;
    items.forEach((item, i) => {
      if (i <= index) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setItems([...item], [...items]);
  };

  const clickProsess = (index) => {
    const items = item;
    items.forEach((item, i) => {
      if (i <= index) {
        item.process = true;
      } else {
        item.process = false;
      }
    });
    setItems([...item], [...items]);
  };

  // ================================================================================

  const Login = (LoginStatus) => {
    if (LoginStatus == 200) {
      set_disable_select_network_input(true);
      click(0);
      clickProsess(1);
      setStatusPage("Approve");
    } else if (LoginStatus == 400) {
      set_disable_select_network_input(false);
    }
  };

  // ================================================================================

  const OrderAmount = () => {
    return orders.amount;
  };

  const FeeAmount = () => {
    if (OrderAmount()) {
      if (BigInt(OrderAmount()) <= BigInt(Max)) {
        return (
          (BigInt(OrderAmount()) * BigInt(DownPercentProfit)) /
            BigInt("10000") +
          BigInt(parseEther(DownFixProfit))
        );
      } else {
        return (
          (BigInt(OrderAmount()) * BigInt(UpPercentProfit)) / BigInt("10000") +
          BigInt(parseEther(UpFixProfit))
        );
      }
    }
  };

  const PaymentAmount = () => {
    if (user_selected_coin == 4) {
      return BigInt(OrderAmount()) * BigInt("1000");
    } else {
      return BigInt(FeeAmount()) + BigInt(OrderAmount());
    }
  };

  const FormatEther = (amount) => {
    if (amount) {
      return formatEther(amount);
    }
  };

  const Approve = async (ApproveStatus) => {
    if (ApproveStatus == 200) {
      if (user_selected_coin == 4) {
        setisDPG(true);
      } else {
        setisDPG(false);
      }
      click(1);
      clickProsess(2);
      setStatusPage("Confirm");
    } else if (ApproveStatus == 400) {
      set_disable_select_coin_input(false);
    } else if (ApproveStatus == 100) {
      set_disable_select_coin_input(true);
    }
  };

  // ================================================================================

  const Confirm = async (ConfirmStatus) => {
    if (ConfirmStatus == 200) {
      click(2);
      clickProsess(3);
      setStatusPage("Check");
      CheckData();
    }
  };

  // ================================================================================

  const CheckData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/pay/CheckData`, {
        Address_Wallet_Approve: "Address_Wallet_Approve",
        TXhash_Approve: "TXhash_Approve",
        Address_Wallet_Confirm: shop.shop_address_wallet,
        TXhash_Confirm: "TXhash_Confirm",
        Amount: orders.amount,
        Back_Order_id: orders.id_order,
        Order_id: order_id,
      })
      .then((res) => {
        if (res.data.status == 20) {
          click(3);
          clickProsess(3);
          setStatusPage("Success");
          window.location = res.data.link + "&status=20&Authority=" + order_id;
        } else {
          click(3);
          clickProsess(3);
          setStatusPage("Failed");
          window.location = res.data.link + "&status=13&Authority=" + order_id;
        }
      });
  };

  // ================================================================================

  useEffect(() => {
    if (user_selected_coin === 4 && user_selected_network !== 56) {
      set_user_selected_coin(1);
      set_select_coin_text("USDT");
    }
  }, [user_selected_network, user_selected_coin]);

  return (
    <>
      <div>
        <div className="main-container container-fluid">
          <div className="page-header row">
            <div className="col-11">
              <h1 className="page-title">Transaction Details</h1>
            </div>
            <div className="col-1">
              <span
                className="d-inline-block"
                tabIndex="0"
                data-toggle="tooltip"
                title="Disconnect"
              ></span>
            </div>
          </div>
          <div className="row row-sm">
            <div className="col-xl-4 col-lg-12 col-md-12">
              <div className="card custom-card">
                <div className="card-body">
                  <h5 className="mb-3">Transaction details</h5>
                  <div className>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="table-responsive">
                          <table className="table mb-0 table-bordered text-nowrap">
                            <tbody>
                              <tr>
                                <th>Shop Name</th>
                                <td>{shop.name_shop}</td>
                              </tr>
                              <tr>
                                <th>Network</th>
                                <td>
                                  {statustoken === true ? (
                                    <>
                                      <div className="col-xl-12">
                                        <button
                                          disabled={
                                            disable_select_network_input
                                          }
                                          type="button"
                                          className="btn btn-outline-primary dropdown-toggle additional"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          {user_selected_network === null ||
                                          user_selected_network == undefined ? (
                                            <>Please Select Network </>
                                          ) : user_selected_network === 56 ? (
                                            <>BSC</>
                                          ) : (
                                            <>Please Select Network</>
                                          )}
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {user_selected_network === null ||
                                        user_selected_network == undefined ? (
                                          <></>
                                        ) : user_selected_network === 56 ? (
                                          <img
                                            src={"/images/pngs/busdbsc.png"}
                                            height={25}
                                            width={25}
                                            className=""
                                          />
                                        ) : (
                                          <></>
                                        )}
                                        <ul
                                          className="dropdown-menu"
                                          role="menu"
                                          style={{}}
                                        >
                                          {/* -------------------------  BSC -------------------------------- */}

                                          <li>
                                            <a
                                              href="javascript:void(0)"
                                              type="bottom"
                                              onClick={() => {
                                                set_user_selected_network(56);
                                              }}
                                            >
                                              <div className="container mx-auto ">
                                                <div className="row">
                                                  <div className="col-8 ">
                                                    BSC
                                                  </div>
                                                  <div className="col ">
                                                    <img
                                                      src={
                                                        "/images/pngs/busdbsc.png"
                                                      }
                                                      height={25}
                                                      width={25}
                                                      className=""
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </a>
                                          </li>

                                          {/* -------------------------------------------------------------------------------------------------- */}
                                        </ul>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="col-xl-12">
                                      <button
                                        disabled={disable_select_network_input}
                                        type="button"
                                        className="btn btn-outline-primary dropdown-toggle additional"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        {user_selected_network === null ||
                                        user_selected_network == undefined ? (
                                          <>Please Select Network </>
                                        ) : user_selected_network === 1 ? (
                                          <>ETH</>
                                        ) : user_selected_network === 56 ? (
                                          <>BSC</>
                                        ) : user_selected_network === 137 ? (
                                          <>polygon</>
                                        ) : (
                                          //  : user_selected_network === 43112 ? (
                                          //   <>Avalanche</>
                                          // ) : user_selected_network === 420 ? (
                                          //   <>optimism</>
                                          // ) : user_selected_network === 421613 ? (
                                          //   <>Arbitrum</>
                                          // ) : user_selected_network ===
                                          //   1666700000 ? (
                                          //   <>harmony one</>
                                          // ) : user_selected_network === 4002 ? (
                                          //   <>fantom</>
                                          // )
                                          <>Please Select Network</>
                                        )}
                                      </button>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      {user_selected_network === null ||
                                      user_selected_network == undefined ? (
                                        <></>
                                      ) : user_selected_network === 1 ? (
                                        <img
                                          src={
                                            "/images/pngs/ethereum-eth-logo.png"
                                          }
                                          height={25}
                                          width={25}
                                          className=""
                                        />
                                      ) : user_selected_network === 56 ? (
                                        <img
                                          src={"/images/pngs/busdbsc.png"}
                                          height={25}
                                          width={25}
                                          className=""
                                        />
                                      ) : user_selected_network === 137 ? (
                                        <img
                                          src={
                                            "/images/pngs/polygon-matic-logo.png"
                                          }
                                          height={25}
                                          width={25}
                                          className=""
                                        />
                                      ) : (
                                        //  : user_selected_network === 43112 ? (
                                        //   <img
                                        //     src={
                                        //       "/images/pngs/avalanche-avax-logo.png"
                                        //     }
                                        //     height={25}
                                        //     width={25}
                                        //     className=""
                                        //   />
                                        // ) : user_selected_network === 420 ? (
                                        //   <img
                                        //     src={
                                        //       "/images/pngs/optimism-ethereum-op-logo.png"
                                        //     }
                                        //     height={25}
                                        //     width={25}
                                        //     className=""
                                        //   />
                                        // ) : user_selected_network === 421613 ? (
                                        //   <img
                                        //     src={"/images/pngs/Arbitrum.png"}
                                        //     height={25}
                                        //     width={25}
                                        //     className=""
                                        //   />
                                        // ) : user_selected_network ===
                                        //   1666700000 ? (
                                        //   <img
                                        //     src={
                                        //       "/images/pngs/harmony-one-logo.png"
                                        //     }
                                        //     height={25}
                                        //     width={25}
                                        //     className=""
                                        //   />
                                        // ) : user_selected_network === 4002 ? (
                                        //   <img
                                        //     src={
                                        //       "/images/pngs/fantom-ftm-logo.png"
                                        //     }
                                        //     height={25}
                                        //     width={25}
                                        //     className=""
                                        //   />
                                        // )
                                        <></>
                                      )}
                                      <ul
                                        className="dropdown-menu"
                                        role="menu"
                                        style={{}}
                                      >
                                        {/* -------------------------  ETH -------------------------------- */}

                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            type="bottom"
                                            onClick={() => {
                                              set_user_selected_network(1);
                                            }}
                                          >
                                            <div className="container mx-auto ">
                                              <div className="row">
                                                <div className="col-8 ">
                                                  ETH
                                                </div>
                                                <div className="col ">
                                                  <img
                                                    src={
                                                      "/images/pngs/ethereum-eth-logo.png"
                                                    }
                                                    height={25}
                                                    width={25}
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </li>

                                        {/* -------------------------  BSC -------------------------------- */}

                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            type="bottom"
                                            onClick={() => {
                                              set_user_selected_network(56);
                                            }}
                                          >
                                            <div className="container mx-auto ">
                                              <div className="row">
                                                <div className="col-8 ">
                                                  BSC
                                                </div>
                                                <div className="col ">
                                                  <img
                                                    src={
                                                      "/images/pngs/busdbsc.png"
                                                    }
                                                    height={25}
                                                    width={25}
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </li>

                                        {/* -------------------------  polygon (matic) -------------------------------- */}

                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            type="bottom"
                                            onClick={() => {
                                              set_user_selected_network(137);
                                            }}
                                          >
                                            <div className="container mx-auto ">
                                              <div className="row">
                                                <div className="col-8 ">
                                                  polygon
                                                </div>
                                                <div className="col ">
                                                  <img
                                                    src={
                                                      "/images/pngs/polygon-matic-logo.png"
                                                    }
                                                    height={25}
                                                    width={25}
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </li>

                                        {/* -------------------------  Avalanche -------------------------------- */}

                                        {/* <li>
                                          <a
                                            href="javascript:void(0)"
                                            type="bottom"
                                            onClick={() => {
                                              set_user_selected_network(43112);
                                            }}
                                          >
                                            <div className="container mx-auto ">
                                              <div className="row">
                                                <div className="col-8 ">
                                                  avalanche
                                                </div>
                                                <div className="col ">
                                                  <img
                                                    src={
                                                      "/images/pngs/avalanche-avax-logo.png"
                                                    }
                                                    height={25}
                                                    width={25}
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </li> */}

                                        {/* -------------------------  optimism -------------------------------- */}

                                        {/* <li>
                                          <a
                                            href="javascript:void(0)"
                                            type="bottom"
                                            onClick={() => {
                                              set_user_selected_network(420);
                                            }}
                                          >
                                            <div className="container mx-auto ">
                                              <div className="row">
                                                <div className="col-8 ">
                                                  optimism
                                                </div>
                                                <div className="col ">
                                                  <img
                                                    src={
                                                      "/images/pngs/optimism-ethereum-op-logo.png"
                                                    }
                                                    height={25}
                                                    width={25}
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </li> */}

                                        {/* -------------------------  Arbitrom -------------------------------- */}
                                        {/* <li>
                                          <a
                                            href="javascript:void(0)"
                                            type="bottom"
                                            onClick={() => {
                                              set_user_selected_network(421613);
                                            }}
                                          >
                                            <div className="container mx-auto ">
                                              <div className="row">
                                                <div className="col-8 ">
                                                  Arbitrom
                                                </div>
                                                <div className="col ">
                                                  <img
                                                    src={
                                                      "/images/pngs/Arbitrum.png"
                                                    }
                                                    height={25}
                                                    width={25}
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </li> */}

                                        {/* -------------------------  Harmony One -------------------------------- */}
                                        {/* <li>
                                          <a
                                            href="javascript:void(0)"
                                            type="bottom"
                                            onClick={() => {
                                              set_user_selected_network(
                                                1666700000
                                              );
                                            }}
                                          >
                                            <div className="container mx-auto ">
                                              <div className="row">
                                                <div className="col-8 ">
                                                  Harmony one
                                                </div>
                                                <div className="col ">
                                                  <img
                                                    src={
                                                      "/images/pngs/harmony-one-logo.png"
                                                    }
                                                    height={25}
                                                    width={25}
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </li> */}

                                        {/* -------------------------  Fantom -------------------------------- */}
                                        {/* <li>
                                          <a
                                            href="javascript:void(0)"
                                            type="bottom"
                                            onClick={() => {
                                              set_user_selected_network(4002);
                                            }}
                                          >
                                            <div className="container mx-auto ">
                                              <div className="row">
                                                <div className="col-8 ">
                                                  Fantom
                                                </div>
                                                <div className="col ">
                                                  <img
                                                    src={
                                                      "/images/pngs/fantom-ftm-logo.png"
                                                    }
                                                    height={25}
                                                    width={25}
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </li> */}

                                        {/* -------------------------------------------------------------------------------------------------- */}
                                      </ul>
                                    </div>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>Coin</th>
                                <td>
                                  <div className="col-xl-12">
                                    <button
                                      disabled={disable_select_coin_input}
                                      type="button"
                                      className="btn btn-outline-primary dropdown-toggle additional"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      {select_coin_text}{" "}
                                      <span
                                        style={{ direction: "rtl" }}
                                        className="caret"
                                      />
                                    </button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {user_selected_coin === null ||
                                    user_selected_coin == undefined ? (
                                      <></>
                                    ) : user_selected_coin === 0 ? (
                                      <img
                                        src={"/images/pngs/busdbsc.png"}
                                        height={25}
                                        width={25}
                                        className=""
                                      />
                                    ) : user_selected_coin === 1 ? (
                                      <img
                                        src={"/images/pngs/usdt-logo.png"}
                                        height={25}
                                        width={25}
                                        className=""
                                      />
                                    ) : user_selected_coin === 2 ? (
                                      <img
                                        src={"/images/pngs/usdc-logo.png"}
                                        height={25}
                                        width={25}
                                        className=""
                                      />
                                    ) : user_selected_coin === 3 ? (
                                      <img
                                        src={"/images/pngs/dai-logo.png"}
                                        height={25}
                                        width={25}
                                        className=""
                                      />
                                    ) : user_selected_coin === 4 ? (
                                      <img
                                        src={"/images/pngs/D-pay-Logo.webp"}
                                        height={25}
                                        width={25}
                                        className=""
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <ul
                                      className="dropdown-menu"
                                      role="menu"
                                      style={{}}
                                    >
                                      {user_selected_network !== 56 ? (
                                        <></>
                                      ) : (
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            type="bottom"
                                            onClick={() => {
                                              set_select_coin_text("DPG");
                                              set_user_selected_coin(4);
                                            }}
                                          >
                                            <div className="container mx-auto ">
                                              <div className="row">
                                                <div className="col-8 ">
                                                  DPG
                                                </div>
                                                <div className="col ">
                                                  <img
                                                    src={
                                                      "/images/pngs/D-pay-Logo.webp"
                                                    }
                                                    height={25}
                                                    width={25}
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </li>
                                      )}

                                      {/* -------------------------  BUSD -------------------------------- */}

                                      {user_selected_network == 4 ||
                                      user_selected_network == 5 ||
                                      user_selected_network == 7 ? (
                                        <></>
                                      ) : (
                                        <li>
                                          <a
                                            href="javascript:void(0)"
                                            type="bottom"
                                            onClick={() => {
                                              set_select_coin_text("BUSD");
                                              set_user_selected_coin(0);
                                            }}
                                          >
                                            <div className="container mx-auto ">
                                              <div className="row">
                                                <div className="col-8 ">
                                                  BUSD
                                                </div>
                                                <div className="col ">
                                                  <img
                                                    src={
                                                      "/images/pngs/busdbsc.png"
                                                    }
                                                    height={25}
                                                    width={25}
                                                    className=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </li>
                                      )}
                                      {/* -------------------------  usdt -------------------------------- */}
                                      <li>
                                        <a
                                          href="javascript:void(0)"
                                          type="bottom"
                                          onClick={() => {
                                            set_select_coin_text("USDT");
                                            set_user_selected_coin(1);
                                          }}
                                        >
                                          <div className="container mx-auto ">
                                            <div className="row">
                                              <div className="col-8 ">USDT</div>
                                              <div className="col ">
                                                <img
                                                  src={
                                                    "/images/pngs/usdt-logo.png"
                                                  }
                                                  height={25}
                                                  width={25}
                                                  className=""
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </a>
                                      </li>

                                      {/* -------------------------  USDC -------------------------------- */}
                                      <li>
                                        <a
                                          href="javascript:void(0)"
                                          type="bottom"
                                          onClick={() => {
                                            set_select_coin_text("USDC");
                                            set_user_selected_coin(2);
                                          }}
                                        >
                                          <div className="container mx-auto ">
                                            <div className="row">
                                              <div className="col-8 ">USDC</div>
                                              <div className="col ">
                                                <img
                                                  src={
                                                    "/images/pngs/usdc-logo.png"
                                                  }
                                                  height={25}
                                                  width={25}
                                                  className=""
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </a>
                                      </li>

                                      {/* -------------------------  DAI -------------------------------- */}
                                      <li>
                                        <a
                                          href="javascript:void(0)"
                                          type="bottom"
                                          onClick={() => {
                                            set_select_coin_text("DAI");
                                            set_user_selected_coin(3);
                                          }}
                                        >
                                          <div className="container mx-auto ">
                                            <div className="row">
                                              <div className="col-8 ">DAI</div>
                                              <div className="col ">
                                                <img
                                                  src={
                                                    "/images/pngs/dai-logo.png"
                                                  }
                                                  height={25}
                                                  width={25}
                                                  className=""
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </a>
                                      </li>

                                      {/* -------------------------------------------------------------------------------------------------- */}
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>Amount</th>
                                <td>
                                  ${FormatEther(OrderAmount())} + $
                                  {FormatEther(FeeAmount())} Fee
                                </td>
                              </tr>
                              <tr>
                                <th>Payment address</th>
                                <td>{shop.shop_address_wallet}</td>
                              </tr>
                              <tr>
                                <th>pay time</th>
                                <td>
                                  <p>
                                    <Timer
                                      CreateTime={orders.createdAt}
                                      order_id={order_id}
                                    />
                                    {/* {time} */}
                                    <ToastContainer />
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="mt-5 text-center">
                          <div className="mt-5 text-center">
                            <Shar />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-12 col-md-12">
              {/* -------------------------------- if Reward == true ---------------------- */}

              <div className="card custom-card overflow-hidden">
                {/* -------------------------------- if Reward == false ---------------------- */}
                {/* ------------------------------------- Time line ----------------------------------------- */}

                <motion.ul>
                  <div className="text-center ">
                    <div className="timeline ">
                      <div
                        className="timeline-progress "
                        style={{ width: `${progressBarWidth}%` }}
                      />
                      <div className="timeline-items ">
                        {items.map((item, i) => (
                          <motion.div
                            key={i}
                            initial={
                              item.process ? { scale: 1.1 } : { scale: 1.1 }
                            }
                            animate={
                              item.process
                                ? { scale: 1.1 }
                                : { opacity: 0.8, scale: 0.9 }
                            }
                            layout
                            className={
                              "timeline-item " +
                              (item.active
                                ? " active"
                                : item.process
                                ? "process"
                                : "")
                            }
                            onClick={() => click(i)}
                          >
                            <div className="timeline-contain ">{item.name}</div>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.ul>

                {/* ------------------------------------- Button  ----------------------------------------- */}

                <motion.div
                  initial={{ opacity: 0, scale: 0.2 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  className="card-body pt-2 "
                  style={{ textalign: "center" }}
                >
                  {StatusPage === "" ? (
                    <>
                      <LoginButton
                        HandleLogin={Login}
                        user_selected_network={user_selected_network}
                        order_id={order_id}
                      />
                    </>
                  ) : StatusPage === "Approve" ? (
                    <>
                      <ApproveButton
                        HandleApprove={Approve}
                        Addresses={networks[user_selected_network].contracts}
                        user_selected_coin={user_selected_coin}
                        PaymentAmount={PaymentAmount()}
                        order_id={order_id}
                      />
                    </>
                  ) : StatusPage === "Confirm" ? (
                    <>
                      <ConfirmButton
                        HandleConfirm={Confirm}
                        orders={orders}
                        order_id={order_id}
                        shop={shop}
                        shop_id={shop_id}
                        User_id={User_id}
                        RouterAddress={
                          networks[user_selected_network].contracts
                            .RouterAddress
                        }
                        user_selected_coin={user_selected_coin}
                        isDPG={isDPG}
                        Url={Url}
                      />
                    </>
                  ) : StatusPage === "Check" ? (
                    <>
                      <motion.div
                        initial={{ opacity: 0, scale: 0.1 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                        className="text-center"
                      >
                        <div
                          className="spinner-border text-secondary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </motion.div>
                    </>
                  ) : StatusPage === "Success" ? (
                    <>
                      <motion.div
                        initial={{ opacity: 0, scale: 0.1 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                        className="text-center"
                      >
                        <div className="alert alert-success" role="alert">
                          Transaction Success
                        </div>
                      </motion.div>
                    </>
                  ) : StatusPage === "Failed" ? (
                    <>
                      <motion.div
                        initial={{ opacity: 0, scale: 0.1 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                        className="text-center"
                      >
                        <div className="alert alert-danger" role="alert">
                          Transaction Failed
                        </div>
                      </motion.div>
                    </>
                  ) : (
                    <></>
                  )}
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

{
  /* ------------------------------------- Array Time line ----------------------------------------- */
}

const items = [
  {
    name: "Select wallet",
    active: false,
    process: true,
  },
  {
    name: "Approve",
    active: false,
    process: false,
  },
  {
    name: "Confirm",
    active: false,
    process: false,
  },
  {
    name: "Success",
    active: false,
    process: false,
  },
];

export default Pay;
