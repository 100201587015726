import axios from "axios";
import { Contract } from "ethers";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAccount } from "wagmi";
import TokenABI from "../busd.json";
import { useEthersProvider } from "../ethers.ts";
import { useEthersSigner } from "../ethersigner.ts";

const ApproveButton = (props) => {
  const account = useAccount();
  const GlobalProvider = useEthersProvider();
  const GlobalSigner = useEthersSigner();
  const [disable, setdisable] = useState(false);

  const ErrorToast = (msg) => {
    const errortoast = toast.error(msg, {
      position: "top-center",
      autoClose: 3000,
      theme: "colored",
    });
    return errortoast;
  };

  const InfoToast = (msg) => {
    const infotoast = toast.info(msg, {
      position: "top-center",
      isLoading: true,
      theme: "colored",
    });
    return infotoast;
  };

  const UpdateToast = (toastobject, msg, type, loading, time) => {
    toast.update(toastobject, {
      render: msg,
      type: type,
      isLoading: loading,
      autoClose: time,
    });
  };

  const ApproveCheck = (owner, spender, value) => {
    if (owner.toLowerCase() != account.address.toLowerCase()) {
      return false;
    }
    if (spender.toLowerCase() != props.Addresses.RouterAddress.toLowerCase()) {
      return false;
    }
    if (value != props.PaymentAmount) {
      return false;
    }
    return true;
  };

  const ApproveErrors = (approvetoast) => {
    UpdateToast(approvetoast, "Transaction Failed", "error", false, 5000);
    setdisable(false);
    props.HandleApprove(400);
  };

  const Approve = async () => {
    if (props.user_selected_coin == null) {
      ErrorToast("Please Select a Token");
      return;
    }
    setdisable(true);
    props.HandleApprove(100);
    const approvetoast = InfoToast("Confirm Approve Request in your Wallet");

    const Payment_Token = props.Addresses.tokens[props.user_selected_coin];
    const Approved_Address = props.Addresses.RouterAddress;

    try {
      const ReadContract = new Contract(
        Payment_Token,
        TokenABI,
        GlobalProvider
      );
      const WriteContract = new Contract(Payment_Token, TokenABI, GlobalSigner);

      const balance = await ReadContract.balanceOf(account.address);
      if (props.PaymentAmount > balance) {
        toast.dismiss(approvetoast);
        ErrorToast("Insufficient Balance");
        setdisable(false);
        props.HandleApprove(400);
        return;
      }

      const ApproveTransaction = await WriteContract.approve(
        Approved_Address,
        props.PaymentAmount
      );
      UpdateToast(
        approvetoast,
        "Approve Request Sent, Please Wait",
        "info",
        true,
        0
      );
      const CompletedApproveTransaction = await ApproveTransaction.wait();
      const TransactionLogs = CompletedApproveTransaction.logs[0].args;

      if (
        ApproveCheck(
          TransactionLogs.owner,
          TransactionLogs.spender,
          TransactionLogs.value
        ) == false
      ) {
        ApproveErrors(approvetoast);
        return;
      }
      const ServerResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/pay/Approve`,
        {
          Address_Wallet_Approve: TransactionLogs.owner,
          TXhash_Approve: CompletedApproveTransaction.hash,
          Order_id: props.order_id,
        }
      );
      if (ServerResponse.data.status != 20) {
        ApproveErrors(approvetoast);
        return;
      }

      UpdateToast(
        approvetoast,
        "Approve Request Completed, Click Confirm",
        "success",
        false,
        5000
      );
      props.HandleApprove(200);
    } catch (error) {
      ApproveErrors(approvetoast);
      console.log(error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.2 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="text-center"
    >
      <button
        disabled={disable}
        onClick={Approve}
        className="btn  btn-info me-3 mt-0 mb-5"
      >
        <h6 className="bi bi-file-earmark-check m-3"> &nbsp;Approve</h6>
      </button>
    </motion.div>
  );
};

export default ApproveButton;
