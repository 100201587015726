import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";
import { watchAccount, watchNetwork } from "wagmi/actions";

const LoginButton = (props) => {
  const [Click, setClick] = useState(false);
  const switchnetwork = useSwitchNetwork();
  const disconnect = useDisconnect();
  const { open } = useWeb3ModalState();
  const [ToastHolder, setToastHolder] = useState();
  const [disable, setdisable] = useState(false);
  const account = useAccount();
  const modal = useWeb3Modal();
  const network = useNetwork();

  const ToastBuilder = (msg) => {
    return toast.info(msg, {
      position: "top-center",
      isLoading: true,
      theme: "colored",
    });
  };

  const ToastUpdater = (toastholder, msg, type, loading, close) => {
    const temptoast = toast.update(toastholder, {
      render: msg,
      type: type,
      isLoading: loading,
      autoClose: close,
    });
    setToastHolder(temptoast);
  };

  const toastupdate = (toastobject, text, status) => {
    toast.update(toastobject, {
      type: status,
      render: text,
      isLoading: false,
      autoClose: 3000,
      theme: "colored",
    });
  };

  const HandleClick = () => {
    if (props.user_selected_network) {
      const toast = ToastBuilder("Please Connect Your Wallet");
      setdisable(true);
      setToastHolder(toast);
      modal.open();
      setClick(true);
    } else {
      toast.error("Please Select Network", {
        position: "top-center",
        autoClose: 5000,
        theme: "colored",
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    if (account.isConnected && Click) {
      if (CheckNetwork()) {
        GoForConnect();
      } else {
        ToastUpdater(
          ToastHolder,
          "Wrong Network Please Switch Network In Your Wallet",
          "warning",
          true,
          0
        );
        switchnetwork
          .switchNetworkAsync?.(props.user_selected_network)
          .then(() => {
            GoForConnect();
          })
          .catch(() => {
            GoForError();
          });
      }
    }
  }, [account.isConnected]);

  const CheckNetwork = () => {
    if (network.chain.id == props.user_selected_network) {
      return true;
    } else {
      return false;
    }
  };

  const GoForError = () => {
    ToastUpdater(
      ToastHolder,
      "Something Went Wrong Please Try Again",
      "error",
      false,
      5000
    );
    setdisable(false);
    disconnect.disconnect();
    setClick(false);
  };

  const GoForConnect = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/pay/selectwallet`, {
        Order_id: props.order_id,
      })
      .then((res) => {
        if (res.data.status == 20) {
          toastupdate(
            ToastHolder,
            "You Are Connected. Click Approve",
            "success"
          );
          RegisterLogin(200);
          const wa = watchAccount((acc) => {
            if (acc.address != account.address) {
              disconnect.disconnect();
              wa();
              window.location.reload();
            }
          });
          const wn = watchNetwork((net) => {
            if (net?.chain?.id != network?.chain?.id) {
              disconnect.disconnect();
              wn();
              window.location.reload();
            }
          });
        } else {
          GoForError();
          RegisterLogin(400);
        }
      });
  };

  useEffect(() => {
    if (open == false && account.isConnected == false) {
      toast.dismiss(ToastHolder);
      setClick(false);
      setdisable(false);
    }
  }, [open]);

  const RegisterLogin = (status) => {
    if (status == 200) {
      props.HandleLogin(200);
    } else if (status == 400) {
      props.HandleLogin(400);
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.2 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="text-center"
    >
      <button
        disabled={disable}
        onClick={HandleClick}
        className="btn  btn-info me-3 mt-0 mb-5"
      >
        <h6 className="m-3"> &nbsp;Connect Wallet</h6>
      </button>
    </motion.div>
  );
};

export default LoginButton;
