import axios from "axios";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAccount } from "wagmi";
import { useEthersProvider } from "../ethers.ts";
import { useEthersSigner } from "../ethersigner.ts";
import { Contract, parseEther, parseUnits } from "ethers";
import RouterAbi from "../Router.json";
import MainRouterAbi from "../MainRouter.json";

const ConfirmButton = (props) => {
  const [disable, setdisable] = useState();
  const account = useAccount();
  const GlobalProvider = useEthersProvider();
  const GlobalSigner = useEthersSigner();
  const Max = "1000000000000000000000";
  const UpFixProfit = "1";
  const UpPercentProfit = "25";
  const DownFixProfit = "1";
  const DownPercentProfit = "0";

  const Get_Receiver_Address = async () => {
    const ShopAddress = props.shop.shop_address_wallet;
    const MainRouterContract = new Contract(
      `${process.env.REACT_APP_Main_Router}`,
      MainRouterAbi,
      GlobalProvider
    );
    const Receiver_Address = (
      await MainRouterContract.GetShopOwner(ShopAddress)
    ).toLowerCase();
    return Receiver_Address;
  };

  const OrderAmount = () => {
    return props.orders.amount;
  };

  const FeeAmount = () => {
    if (OrderAmount()) {
      if (BigInt(OrderAmount()) <= BigInt(Max)) {
        return (
          (BigInt(OrderAmount()) * BigInt(DownPercentProfit)) /
            BigInt("10000") +
          BigInt(parseEther(DownFixProfit))
        );
      } else {
        return (
          (BigInt(OrderAmount()) * BigInt(UpPercentProfit)) / BigInt("10000") +
          BigInt(parseEther(UpFixProfit))
        );
      }
    }
  };

  const PaymentAmount = () => {
    if (props.user_selected_coin == 4) {
      return BigInt(OrderAmount()) * BigInt("1000");
    } else {
      return BigInt(FeeAmount()) + BigInt(OrderAmount());
    }
  };

  const InfoToast = (msg) => {
    const infotoast = toast.info(msg, {
      position: "top-center",
      isLoading: true,
      theme: "colored",
    });
    return infotoast;
  };

  const UpdateToast = (toastobject, msg, type, loading, time) => {
    toast.update(toastobject, {
      render: msg,
      type: type,
      isLoading: loading,
      autoClose: time,
    });
  };

  const isDonate = () => {
    if (
      props.orders.donate == true &&
      props.orders.tokendata.token_Address &&
      props.orders.tokendata.token_Amount > 0
    ) {
      return true;
    }
    return false;
  };

  const ConfirmDonateCheck = async (
    sender,
    receiver,
    value,
    tokenvalue,
    tokenaddress,
    time
  ) => {
    if (sender.toLowerCase() != account.address.toLowerCase()) {
      return false;
    }
    if (receiver.toLowerCase() != (await Get_Receiver_Address())) {
      return false;
    }
    if (value != PaymentAmount()) {
      return false;
    }
    if (
      tokenvalue !=
      parseUnits(
        props.orders.tokendata.token_Amount,
        props.orders.tokendata.token_Decimal
      )
    ) {
      return false;
    }
    if (tokenaddress != props.orders.tokendata.token_Address) {
      return false;
    }
    const createtime = Math.floor(
      new Date(props.orders.createdAt).getTime() / 1000
    );
    if (time < BigInt(createtime)) {
      return false;
    }
    return true;
  };

  const ConfirmPayCheck = async (sender, receiver, value, time) => {
    if (sender.toLowerCase() != account.address.toLowerCase()) {
      return false;
    }
    if (receiver.toLowerCase() != (await Get_Receiver_Address())) {
      return false;
    }
    if (value != PaymentAmount()) {
      return false;
    }
    const createtime = Math.floor(
      new Date(props.orders.createdAt).getTime() / 1000
    );
    if (time < BigInt(createtime)) {
      return false;
    }
    return true;
  };

  const ConfirmError = (confirmtoast) => {
    UpdateToast(confirmtoast, "Transaction Failed", "error", false, 5000);
    setdisable(false);
    props.HandleConfirm(400);
  };

  const Confirm = async () => {
    setdisable(true);
    props.HandleConfirm(100);

    const confirmtoast = InfoToast("Confirm Request in your Wallet");

    var CompletedConfirmTransaction;
    var ConfirmTransaction;
    var Array_Index;

    const RouterAddress = props.RouterAddress;
    const Receiver_Address = await Get_Receiver_Address();

    const ReadContract = new Contract(RouterAddress, RouterAbi, GlobalProvider);
    const WriteContract = new Contract(RouterAddress, RouterAbi, GlobalSigner);

    const TokenRewarddata = props.orders.tokendata;

    try {
      var paymentamount = BigInt(OrderAmount());
      if (props.isDPG) {
        paymentamount = paymentamount * BigInt("1000");
      }
      const stringpaymentamount = paymentamount.toString();

      if (isDonate()) {
        let Nonce = await ReadContract.GetNonce();
        Nonce = parseInt(Nonce) + 1;
        const Token_Reward_Amount = parseUnits(
          TokenRewarddata.token_Amount,
          TokenRewarddata.token_Decimal
        ).toString();
        const Signature = await axios.post(
          `${process.env.REACT_APP_API_URL}/pay/CheckOrder`,
          {
            Order_id: props.order_id,
            MR: props.Url.slice(0, 64),
            amount: stringpaymentamount,
            shop_id: props.shop_id,
            User_id: props.User_id,
            user_selected_coin: props.user_selected_coin,
            Receiver_Address,
            Token_Reward_Amount,
            Nonce,
            Token_Address: TokenRewarddata.token_Address,
            isDPG: props.isDPG,
          }
        );
        if (Signature.status != 200) {
          ConfirmError(confirmtoast);
          return;
        }
        ConfirmTransaction = await WriteContract.TokenRouter(
          props.user_selected_coin,
          Receiver_Address,
          stringpaymentamount,
          TokenRewarddata.token_Address,
          Token_Reward_Amount,
          Nonce,
          Signature.data.signature
        );
        Array_Index = 6;
        if (props.isDPG) {
          Array_Index = Array_Index - 2;
        }
        UpdateToast(
          confirmtoast,
          "Confirm Request Sent, Please Wait",
          "info",
          true,
          0
        );
        CompletedConfirmTransaction = await ConfirmTransaction.wait();
        const ConfirmTransactionLogs =
          CompletedConfirmTransaction.logs[Array_Index].args;
        const CheckResult = await ConfirmDonateCheck(
          ConfirmTransactionLogs.sender,
          ConfirmTransactionLogs.shop,
          ConfirmTransactionLogs.price,
          ConfirmTransactionLogs.tokenpaid,
          ConfirmTransactionLogs.tokenaddress,
          ConfirmTransactionLogs.timestamp
        );
        if (CheckResult == false) {
          ConfirmError(confirmtoast);
          return;
        }
      } else {
        ConfirmTransaction = await WriteContract.Router(
          props.user_selected_coin,
          Receiver_Address,
          paymentamount
        );
        Array_Index = 4;
        if (props.isDPG) {
          Array_Index = Array_Index - 2;
        }
        UpdateToast(
          confirmtoast,
          "Confirm Request Sent, Please Wait",
          "info",
          true,
          0
        );
        CompletedConfirmTransaction = await ConfirmTransaction.wait();
        const ConfirmTransactionLogs =
          CompletedConfirmTransaction.logs[Array_Index].args;
        const CheckResult = await ConfirmPayCheck(
          ConfirmTransactionLogs.sender,
          ConfirmTransactionLogs.shop,
          ConfirmTransactionLogs.price,
          ConfirmTransactionLogs.timestamp
        );
        if (CheckResult == false) {
          ConfirmError(confirmtoast);
          return;
        }
      }

      const ConfirmResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/pay/Confirm`,
        {
          Address_Wallet_Confirm: account.address,
          TXhash_Confirm: CompletedConfirmTransaction.hash,
          Order_id: props.order_id,
        }
      );

      if (ConfirmResponse.data.status != 20) {
        ConfirmError(confirmtoast);
        return;
      }
      UpdateToast(
        confirmtoast,
        "Transaction Completed",
        "success",
        false,
        5000
      );
      props.HandleConfirm(200);
    } catch (error) {
      console.log(error);
      ConfirmError(confirmtoast);
    }
  };

  // ================================================================================

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.1 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="text-center"
    >
      <button
        disabled={disable}
        onClick={Confirm}
        className="btn  btn-info me-3 mt-0 mb-5"
      >
        <h6 className="bi bi-check-circle-fill m-3"> &nbsp; Confirm </h6>
      </button>
    </motion.div>
  );
};

export default ConfirmButton;
